img.reminder {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
}

p.first {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  -webkit-animation-delay: 1s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation: fadeIn 1s 1s;
  -o-animation: fadeIn 1s 1s;
  -ms-animation: fadeIn 1s 1s;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
}

p.second {
  font-size: 24px;
  font-family: 'Garamond';
  font-weight: bold;
  font-style: italic;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  -webkit-animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation: fadeIn 1s 2s;
  -o-animation: fadeIn 1s 2s;
  -ms-animation: fadeIn 1s 2s;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
}

span.third {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-delay: 3s;
  animation-fill-mode: forwards;
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  -webkit-animation-delay: 3s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation: fadeIn 1s 3s;
  -o-animation: fadeIn 1s 3s;
  -ms-animation: fadeIn 1s 3s;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
}

button.lets-go {
  opacity: 0;
  animation:
    fadeIn 1s 5s,
    glow 2500ms linear infinite 2000ms;
  -webkit-animation:
    fadeIn 1s 5s,
    glow 2500ms linear infinite 2000ms;
  -moz-animation:
    fadeIn 1s 5s,
    glow 2500ms linear infinite 2000ms;
  -o-animation:
    fadeIn 1s 5s,
    glow 2500ms linear infinite 2000ms;
  -ms-animation:
    fadeIn 1s 5s,
    glow 2500ms linear infinite 2000ms;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

span.about {
  font-family: 'Garamond';
  font-weight: bold;
}

span.do {
  font-family: 'Helvetica';
  font-weight: bold;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes glow {
  40% {
    box-shadow: 0 0 10px #000;
  }
}
