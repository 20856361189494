body {
  font-family: adelle-sans, sans-serif;
}

.MuiOutlinedInput-root {
  width: 248px;
  background: white;
  height: 44px;
}

.aa-SubmitButton svg {
  color: rgba(0, 0, 0, 0.54) !important;
}

.tab {
  color: #623d1c;
}

.tab.selected {
  color: #d60d51;
}

.tab.selected::after {
  position: absolute;
  bottom: -1px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 8px 10px;
  border-color: transparent transparent #d60d50 transparent;
  border-radius: 1px;
}

.MuiButton-root {
  border-radius: 16px !important;
  text-transform: none !important;
  font-size: 14px !important;
}
